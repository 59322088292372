import React, { useEffect } from "react"
import { Button, Col, message, Modal, Row, Spin, Tag } from "antd"

import CustomBreadcrumb from "../../components/commonBreadcrumb"
import Template from "../../layouts/base"
import { navigate, useIntl } from "gatsby-plugin-intl"
import withAuth from "../../components/withAuthHoc"
import authFetch from "../../services/network"
import { checkPermissions } from "../../services/auth/permissions/permissions"
import Gravatar from "react-gravatar"
import useStateObject from "../../hooks/useStateObject"
import { getUser } from "../../services/auth"

const { confirm } = Modal
const AVATAR_SIZE = 150

const UserDetail = ({ location }) => {
  const intl = useIntl()
  const [state, setState] = useStateObject({
    userId: location && location.pathname.split("/").pop(),
    user: null,
    processing: false,
    loggedUser: null,
  })

  useEffect(() => {
    getUser().then(loggedUser => {
      authFetch(`${process.env.GATSBY_CONF_API_URL}/users/${state.userId}`)
        .then(response => {
          response.json().then(user => {
            if (200 === response.status) {
              setState({ loggedUser, user })
            }
          })
        })
        .catch(() => {
          message.error(
            intl.formatMessage({
              id: "page-user-get-detail-request-error-text",
            })
          )
        })
    })
  }, [])

  const goToEditUser = () => {
    navigate(`/user/edit/${state.userId}`)
  }

  const deleteUser = () => {
    const _user = state.user
    confirm({
      title: intl.formatMessage({ id: "modal-are-you-sure" }),
      content: intl.formatMessage(
        { id: "page-user-delete-user-modal-text" },
        { name: _user.name, last_name: _user.last_name }
      ),
      okText: intl.formatMessage({ id: "modal-ok" }),
      okType: "danger",
      cancelText: intl.formatMessage({ id: "modal-ko" }),
      onOk() {
        setState({ processing: true })
        authFetch(`${process.env.GATSBY_CONF_API_URL}/users/${_user.id}`, {
          method: "DELETE",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then(response => {
            setState({ processing: false })
            if (response.status !== 204) {
              message.error(
                intl.formatMessage({
                  id: "page-user-delete-user-error-text",
                })
              )
            } else {
              message.success(
                intl.formatMessage({
                  id: "page-user-delete-user-ok-text",
                })
              )
              navigate(`/user`)
            }
          })
          .catch(() => {
            setState({ processing: false })
            message.error(
              intl.formatMessage({
                id: "page-user-delete-user-error-text",
              })
            )
          })
      },
      onCancel() {
        message.info(intl.formatMessage({ id: "modal-delete-cancel" }))
      },
    })
  }

  const isTheLoggedUser = () => {
    const { loggedUser, user } = state
    return loggedUser && user ? loggedUser.id === user.id : false
  }

  return (
    <Template selected={["user", "user-detail"]}>
      <CustomBreadcrumb
        crumbs={[
          {
            label: intl.formatMessage({ id: "menu-user-management" }),
            link: "/user",
          },
          intl.formatMessage({ id: "menu-user-detail" }),
        ]}
      />
      <Row style={{ display: "flex", alignItems: "center" }}>
        <Col span={18} className="knolar-intro">
          {intl.formatMessage({ id: "menu-user-detail" })}
        </Col>
        <Col span={6} style={{ display: "flex", justifyContent: "flex-end" }}>
          {!isTheLoggedUser() && checkPermissions(["user:delete"]) ? (
            <Button
              className="knolar-button back"
              type="secondary"
              style={{ marginRight: "20px" }}
              onClick={deleteUser}
              loading={state.processing}
            >
              {intl.formatMessage({
                id: "page-user-delete-button-text",
              })}
            </Button>
          ) : null}
          {checkPermissions(["user:write"]) ? (
            <Button
              className="knolar-button"
              type="primary"
              onClick={goToEditUser}
            >
              {intl.formatMessage({
                id: "page-user-update-button-text",
              })}
            </Button>
          ) : null}
        </Col>
      </Row>
      <div className="content">
        {state.user ? (
          <Row style={{ display: "flex" }}>
            <Col style={{ width: AVATAR_SIZE, marginRight: 20 }}>
              <Gravatar
                email={state.user.email}
                size={AVATAR_SIZE}
                style={{ borderRadius: "8px" }}
              />
            </Col>
            <Col
              style={{
                display: "flex",
                marginTop: "3em",
                justifyContent: "space-around",
                width: "100%",
              }}
            >
              <Col>
                <div className="detail-title">
                  {intl.formatMessage({
                    id: "page-user-name-label",
                  })}
                  :
                </div>
                <div>
                  <strong>{state.user.name}</strong>
                </div>
              </Col>
              <Col>
                <div className="detail-title">
                  {intl.formatMessage({
                    id: "page-user-last-name-label",
                  })}
                  :
                </div>
                <div>
                  <strong>{state.user.last_name}</strong>
                </div>
              </Col>
              <Col>
                <div className="detail-title">Email:</div>
                <div>{state.user.email}</div>
              </Col>
              <Col style={{ maxWidth: "40%", marginRight: "20px" }}>
                <div className="detail-title">
                  {intl.formatMessage({
                    id: "page-user-form-assigned-role-fields",
                  })}
                  :
                </div>
                {state.user.roles.length > 0 ? (
                  state.user.roles.map(role => (
                    <Tag className="role-tag" key={role}>
                      {role}
                    </Tag>
                  ))
                ) : (
                  <div style={{ color: "#aba7a7", fontStyle: "italic" }}>
                    {" "}
                    {intl.formatMessage({
                      id: "page-user-empty-roles-placeholder",
                    })}
                  </div>
                )}
              </Col>
            </Col>
          </Row>
        ) : (
          <Row
            style={{
              height: "200px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Spin size="large" />
          </Row>
        )}
      </div>
    </Template>
  )
}

export default withAuth(UserDetail)
